<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4>View Results</h4>
                                <div class="breadcrumb-sub-header">
                                <router-link to="/dashboard">Dashboard </router-link>\ Online Course Results
                                </div>
                            </div>
                        </div>
                        <div class="breadcrumb d-flex justify-space-between">
                            <div>
                                <div class="title"> <strong> Enroller: </strong> {{candidate.owner ? candidate.owner.full_name : ''}} </div>
                                <div class="title"> <strong> Phone: </strong> {{candidate.owner ? candidate.owner.phone : '' }} {{candidate.owner ? candidate.owner.mobile : '' }} </div>
                            </div>
                            <div class="text-left ml-2">
                                <div class="title"> <strong> Email: </strong> {{candidate.owner ? candidate.owner.email : ''}} </div>
                                <div class="title"> <strong> State: </strong> {{candidate.owner ? candidate.owner.score_name : ''}} </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <v-skeleton-loader
                            type="table-thead"
                            v-if="loading"
                        >
                        </v-skeleton-loader>

                        <v-skeleton-loader
                            v-if="loading"
                            type="table-row-divider@5"
                        >
                        </v-skeleton-loader>

                        <table v-if="!loading" class="table  table-vertical-center"
                                id="kt_advance_table_widget_4">
                            <thead>
                                <tr class="text-left">
                                    <th class="px-3">Attempt ID</th>
                                    <th class="px-3">Enrolment key</th>
                                    <th class="px-3">Result Type</th>
                                    <th class="px-3">Submitted Date</th>
                                    <th class="px-3">Status</th>
                                    <th class="px-3">Result</th>
                                    <th class="pr-3 text-center">Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template>
                                    <tr v-if="examResults.length != 0" v-for="(result, index) in examResults" :key="index">
                                        <td class="px-2">
                                            <span  class=""
                                            >
                                                {{result.attempt_id}}
                                            </span>
                                        </td>
                                        <td class="px-2">
                                            <span  class="font-weight-bolder d-block font-size-lg"
                                            >
                                                {{result.candidate.exam_key}}
                                            </span>
                                        </td>
                                        <td class="px-2">
                                            <span  class="font-weight-bolder d-block font-size-lg"
                                            >
                                                {{result.is_official ? 'Official' : 'Practice' }}
                                            </span>
                                        </td>
                                        <td class="px-2">
                                            <span  class="font-weight-bolder d-block font-size-lg"
                                            >
                                                {{result.formatted_completion_time ? result.formatted_completion_time : '-'}}
                                            </span>
                                        </td>
                                        <td class="px-2">
                                            <span  class="font-weight-bolder d-block font-size-lg"
                                            >
                                                {{result.exam_status_text}}
                                            </span>
                                        </td>
                                        <td class="px-2">
                                            <span v-if="result.is_marked && result.exam_status == 'completed'" class="badge badge-success"
                                            >
                                                Available
                                            </span>
                                            <span class="badge badge-warning" v-else>
                                                Pending
                                            </span>
                                        </td>
                                        <td class="pr-0 text-center">
                                            <template>
                                                <b-dropdown
                                                    size="sm"
                                                    variant="link"
                                                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                    no-caret
                                                    right
                                                    no-flip
                                                >
                                                    <template v-slot:button-content>
                                                    <i class="ki ki-bold-more-hor"></i>
                                                    </template>
                                                    <!--begin::Navigation-->
                                                    <div v-if="! result.is_official" class="navi navi-hover min-w-md-250px">
                                                        <b-dropdown-text tag="div" class="navi-item">
                                                            <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="darken-2">fas fa-edit</v-icon>
                                                            </span>
                                                            <span class="navi-text">
                                                                Mark as Complete
                                                            </span>
                                                            </a>
                                                        </b-dropdown-text>
                                                    </div>

                                                    <div v-if="result.is_official" class="navi navi-hover min-w-md-250px">
                                                        <b-dropdown-text tag="div" class="navi-item">
                                                            <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="darken-2">fas fa-exclamation-triangle</v-icon>
                                                            </span>
                                                            <span class="navi-text">
                                                                Grant Second Official Exam Attempt
                                                            </span>
                                                            </a>
                                                        </b-dropdown-text>

                                                        <b-dropdown-text tag="div" @click="viewMarksObtained(result)" class="navi-item">
                                                            <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="darken-2">fas fa-chart-line</v-icon>
                                                            </span>
                                                            <span class="navi-text">
                                                                View Marks Obtained
                                                            </span>
                                                            </a>
                                                        </b-dropdown-text>

                                                        <b-dropdown-text tag="div" @click="viewFeedbackReport(result.id)" class="navi-item">
                                                            <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="darken-2">fas fa-file</v-icon>
                                                            </span>
                                                            <span class="navi-text">
                                                                View Feedback Report
                                                            </span>
                                                            </a>
                                                        </b-dropdown-text>

                                                        <b-dropdown-text @click="printDigitalCertificate(result.id)" tag="div" class="navi-item">
                                                            <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="darken-2">fas fa-print</v-icon>
                                                            </span>
                                                            <span class="navi-text">
                                                                Print Digital Certificates
                                                            </span>
                                                            </a>
                                                        </b-dropdown-text>

                                                        <b-dropdown-text @click="viewSupervisorInormation(result.id)" tag="div" class="navi-item">
                                                            <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="darken-2">fas fa-user</v-icon>
                                                            </span>
                                                            <span class="navi-text">
                                                                Supervisor Information
                                                            </span>
                                                            </a>
                                                        </b-dropdown-text>

                                                        <b-dropdown-text tag="div" class="navi-item">
                                                            <a  class="navi-link">
                                                            <span class="navi-icon">
                                                                <v-icon color="darken-2">fas fa-sync</v-icon>
                                                            </span>
                                                            <span class="navi-text">
                                                                Resync Data with RS
                                                            </span>
                                                            </a>
                                                        </b-dropdown-text>
                                                    </div>
                                                </b-dropdown>
                                            </template>
                                        </td>
                                    </tr>
                                    <tr v-if="examResults.length == 0">
                                        <td colspan="7" class="text-center">
                                            No results found
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
import CandidateService from "@/services/candidate/CandidateService";
import CandidateOnlineCourseResult from "@/services/candidate/online-course-result/CandidateOnlineCourseResult";

const candidate = new CandidateService();
const onlineCourseResult = new CandidateOnlineCourseResult();

export default {
    data(){
        return {
            examKey: '',
            candidate_id: '',
            examResults: [],
            loading: true,
            showMarksObtainedDialog: false,
            showSupervisorDialog: false,
            mark: null,
            availableMarks: null,
            remarks: '',
            candidate: '',
            examSupervisor: ''
        }
    },
    methods:{
        getExamKey(){
            this.examKey = this.$route.params.examKey;
        },
        getCandidateInfo(){
            this.loading = true;
            candidate
            .getCandidateSummary(this.examKey)
            .then((response) => {
                this.candidate = response.data.candidate;
                this.candidate_id = response.data.candidate.id;
                this.getOnlineCourseResult();
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
            })
            .finally(() => {
                this.loading = false;
            })
        },
        getOnlineCourseResult(){
            onlineCourseResult
            .getCourseResult(this.candidate_id)
            .then((response) => {
                this.examResults = response.data.examResult;
            })
            .catch((err) => {

            })
            .finally(() => {

            });
        },
        closeDialog(){
            this.showMarksObtainedDialog = false;
            this.showSupervisorDialog = false;
        },
    },
    mounted(){
        this.getExamKey();
        this.getCandidateInfo();
    }
};
</script>